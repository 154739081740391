// context/LanguageContext.jsx
import React, { createContext, useState, useContext } from "react";
import websiteImage from "../assets/images/Website.png";
import rsvpImage from "../assets/images/RSVP.png";
import photosImage from "../assets/images/Gallery.png";

const LanguageContext = createContext();

const translations = {
  en: {
    homepage: {
      heading1: "2 for 3799,-",
      heading2: "3 for 4999,-",
      howItWorks1: "Click the images and discover how these products ",
      howItWorks2: "can make your next event unforgettable!",
    },
    cart: {
      othersBought: "Others also bought",
      orderFailed: "Order failed, please try again.",
      orderConfirmed: "Order confirmed, redirecting..",
      toCart: "Added to cart",
      hi: "Hi ",
      haveSent: "We have sent you an email with your order details.",
      order: "Order: ",
      totalPrice: "Total Price: ",
      congratulations: "Congratulations! 🎉",
      toHome: "Back to Home",
      nameRequired: "Please enter your name",
      validEmailRequired: "Please enter a valid email",
      yourCart: "Your Cart",
      noItems: "No items in cart",
      subtotal: "Subtotal:",
      discount: "Discount:",
      total: "Total:",
      name: "Name",
      email: "Email",
      payWith: "Pay with",
      payWhen: "Pay when you have received your order",
      orderNow: "Order now",
      termsAndConditions: "Terms & Conditions",
      terms1: "1. The resale of this product is not permitted.",
      terms2:
        "2. Purchasing more than one product does not entitle you to multiple hosting services or domain names.",
      terms3: "3. All sales are final and non-refundable.",
      disagree: "Disagree",
      agree: "Agree",
      paymentSuccess: "Payment successful!",
      agreeTerms: "Please agree to the ",
    },
    productPage: {
      clickToSee: "See example site",
      addToCart: "Add to cart",
    },
    productCard: {
      addToCart: "Add to cart",
    },
    products: [
      {
        id: 1,
        name: "Website",
        price: "1999,-",
        description:
          "Make your event unforgettable with a custom-designed website!",
        longDescription1:
          "Bring everything together in one place – detailed information about the plans, location, schedule, and contact details.",

        longDescription2:
          "Let your guests get to know you and your event through beautiful images and personal stories. This isn’t just a website – it’s a digital keepsake that sets the standard for all future events.",

        longDescription3:
          "Build excitement and provide your guests with a seamless experience, both before and after your big day!",

        address: "https://bryllupsnettside.netlify.app/",
        image: websiteImage,
        included: [
          "Personalized design and content",
          "1 year hosting (5GB)",
          "1 year custom domain",
          "Designated QR-code",
          "Google Maps integration",
          "Mobile & PC friendly",
          "20 personalized email addresses",
          "Pay when you have received the website",
        ],
      },
      {
        id: 2,
        name: "RSVP",
        price: "1999,-",
        description:
          "Say goodbye to chaotic invitations and incomplete responses!",
        longDescription1:
          "With our RSVP website, you can send beautiful, digital invitations that set the mood for your big day!",

        longDescription2:
          "Not only do the invitations look stunning with animations and elegant designs, but you can also easily track who has responded and who is attending.",

        longDescription3:
          "Make it easy for your guests to reply while keeping full control over the guest list – all in a user-friendly solution. Perfect for ensuring your day goes off without a hitch!",

        address: "https://rsvp-arrangement.netlify.app/",
        image: rsvpImage,
        included: [
          "Personalized design and content",
          "1 year hosting (20GB)",
          "1 year custom domain",
          "RSVP form",
          "Designated QR-code",
          "Mobile & PC friendly",
          "50 personalized email addresses",
          "Pay when you have received the website",
        ],
      },
      {
        id: 3,
        name: "Gallery",
        price: "1999,-",
        description:
          "Preserve the memories of your big day in an easy and shareable way!",

        longDescription1:
          "Our gallery website allows both guests and hosts to upload and download photos and videos from the occasion, accessible via a convenient QR-code placed around the venue.",

        longDescription2:
          "Create a digital community where everyone can share and relive their best moments. The gallery is available to all, and with simple downloads, everyone can keep their cherished memories close at hand forever.",

        longDescription3:
          "Make the day even more special with a gallery that captures all the unforgettable moments!",

        address: "https://qr-arrangement.netlify.app/",
        image: photosImage,
        included: [
          "Personalized design and content",
          "1 year hosting (20GB)",
          "1 year custom domain",
          "Designated QR-code",
          "Photo gallery",
          "Video gallery",
          "Mobile & PC friendly",
          "50 personalized email addresses",
          "Pay when you have received the website",
        ],
      },
    ],
  },
  no: {
    homepage: {
      heading1: "2 for 3799,-",
      heading2: "3 for 4999,-",
      howItWorks1: "Klikk på bildene og se hvordan disse produktene ",
      howItWorks2: "kan gjøre ditt neste event uforglemmelig!",
    },
    cart: {
      othersBought: "Andre kjøpte også",
      orderFailed: "Ordre mislyktes, vennligst prøv igjen.",
      orderConfirmed: "Ordre bekreftet, vennligst vent..",
      toCart: "Lagt til i handlekurven",
      congratulations: "Gratulerer! 🎉",
      hi: "Hei ",
      haveSent: "Vi har sendt deg en e-post med ordredetaljene dine.",
      order: "Ordre: ",
      totalPrice: "Total pris: ",
      toHome: "Til Startsiden",
      nameRequired: "Vennligst skriv inn navnet ditt",
      validEmailRequired: "Vennligst skriv inn en gyldig e-post",
      yourCart: "Handlekurven din",
      noItems: "Ingen varer i handlekurven",
      subtotal: "Delsum:",
      discount: "Rabatt:",
      total: "Totalt:",
      name: "Navn",
      email: "E-post",
      payWith: "Betal med",
      payWhen: "Betal når du har mottatt produktene dine",
      orderNow: "Bestill",
      termsAndConditions: "Vilkår & betingelser",
      terms1: "1. Videresalg av dette produktet er ikke tillatt.",
      terms2:
        "2. Kjøp av mer enn ett produkt gir deg ikke rett til flere vertstjenester eller domenenavn.",
      terms3: "3. Alle salg er endelige og ikke-refunderbare.",
      disagree: "Uenig",
      agree: "Enig",
      paymentSuccess: "Betaling vellykket!",
      agreeTerms: "Vennligst godta våre",
    },
    productPage: {
      clickToSee: "Se eksempelside",
      addToCart: "Legg i handlekurv",
    },
    productCard: {
      addToCart: "Legg i handlekurv",
    },
    products: [
      {
        id: 1,
        name: "Nettside",
        price: "1999,-",
        description:
          "Gjør ditt arrangement uforglemmelig med en skreddersydd nettside!",
        longDescription1:
          "Samle alt på ett sted – detaljert informasjon om planer, lokasjon, tidspunkter, og kontaktinformasjon.",

        longDescription2:
          "La gjestene bli kjent med dere og arrangementet gjennom vakre bilder og personlige historier. Dette er ikke bare en nettside – det er et digitalt minnesmerke som setter standarden for alle fremtidige arrangementer.",
        longDescription3:
          "Skap forventning og gi gjestene en sømløs opplevelse, både før og etter den store dagen!",
        address: "https://bryllupsnettside.netlify.app/",
        image: websiteImage,
        included: [
          "Personlig design og innhold",
          "1 års hosting (5GB)",
          "1 års personlig domene",
          "Egen QR-kode",
          "Google Maps-integrasjon",
          "Mobil- og PC-vennlig",
          "20 personlige e-postadresser",
          "Betal når du har mottatt nettsiden",
        ],
      },
      {
        id: 2,
        name: "RSVP",
        price: "1999,-",
        description: "Glem kaotiske invitasjoner og ufullstendige svar!",

        longDescription1:
          "Med vår RSVP-nettside kan du sende vakre, digitale invitasjoner som setter stemningen for din store dag!",

        longDescription2:
          "Ikke bare ser invitasjonene fantastiske ut med animasjoner og elegant design, men du kan også enkelt følge med på hvem som har svart, og hvem som kommer.",

        longDescription3:
          "Gjør det lett for dine gjester å svare, samtidig som du holder full kontroll over deltakerlisten – alt i en brukervennlig løsning. Perfekt for å sikre at dagen går knirkefritt!",

        address: "https://rsvp-arrangement.netlify.app/",
        image: rsvpImage,
        included: [
          "Personlig design og innhold",
          "1 års hosting (20GB)",
          "1 års personlig domene",
          "RSVP-skjema",
          "Egen QR-kode",
          "Mobil- og PC-vennlig",
          "50 personlige e-postadresser",
          "Betal når du har mottatt nettsiden",
        ],
      },
      {
        id: 3,
        name: "Galleri",
        price: "1999,-",
        description:
          "Bevar minnene fra den store dagen på en enkel og delbar måte! ",

        longDescription1:
          "Vårt gallerinettsted lar både gjester og vertskap laste opp og laste ned bilder og videoer fra anledningen, tilgjengelig via en praktisk QR-kode plassert rundt omkring lokalet.",

        longDescription2:
          "Skap et digitalt fellesskap hvor alle kan dele og gjenoppleve sine beste øyeblikk. Galleriet er åpent for alle, og med enkle nedlastinger kan alle bevare sine beste minner for alltid.",

        longDescription3:
          "Gjør dagen enda mer spesiell med et galleri som fanger alle de uforglemmelige øyeblikkene!",

        address: "https://qr-arrangement.netlify.app/",
        image: photosImage,
        included: [
          "Personlig design og innhold",
          "1 års hosting (20GB)",
          "1 års personlig domene",
          "Egen QR-kode",
          "Fotogalleri",
          "Videogalleri",
          "Mobil- og PC-vennlig",
          "50 personlige e-postadresser",
          "Betal når du har mottatt nettsiden",
        ],
      },
    ],
  },
};

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState("no");

  const changeLanguage = () => {
    setLanguage(language === "no" ? "en" : "no");
  };

  const getTranslation = (key) => {
    const keys = key.split(".");
    let translation = translations[language];
    for (let k of keys) {
      translation = translation[k];
    }
    return translation;
  };

  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, getTranslation }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
