// // pages/ProductPage.jsx
// import React, { useContext } from "react";
// import { useLocation } from "react-router-dom";
// import styled from "styled-components";
// import ButtonCartIcon from "../assets/images/button-cart-icon.png";
// import { CartContext } from "../context/CartContext";
// import Check from "../assets/images/check.png";
// import { useLanguage } from "../context/LanguageContext";
// import ScrollToTop from "../components/ScrollToTop";

// const StyledDiv = styled.div`
//   display: flex;
//   align-items: center;
//   margin: 3rem auto;
//   width: 90vw;
//   justify-content: space-around;
//   gap: 2rem;

//   @media (max-width: 1199px) {
//     flex-direction: column;
//   }
// `;

// const StyledImg = styled.img`
//   width: 40vw;
//   max-width: 600px;

//   @media (max-width: 1199px) {
//     width: 80vw;
//   }
// `;

// const StyledCartIcon = styled.img`
//   width: 20px;
//   margin-right: 5px;
// `;

// const StyledInfoDiv = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
// `;

// const StyledCheck = styled.img`
//   width: 20px;
// `;

// const StyledCheckDiv = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 5px;
//   font-size: 1.1rem;
//   margin-bottom: 0.5rem;
// `;

// const ProductPage = () => {
//   const location = useLocation();
//   const { product: initialProduct } = location.state;
//   const { cart, addToCart } = useContext(CartContext);
//   const { getTranslation } = useLanguage();

//   const products = getTranslation("products");
//   const product =
//     products.find((p) => p.id === initialProduct.id) || initialProduct;

//   ScrollToTop();

//   const openProductLink = () => {
//     window.open(product.address, "_blank"); // Open product address in new tab
//   };

//   const showOtherProducts = () => {
//     console.log("Show other products");
//   };

//   return (
//     <StyledDiv>
//       <a href={product.address} target="_blank" rel="noreferrer">
//         <StyledImg
//           src={product.image}
//           alt={product.name}
//           onClick={openProductLink}
//         />
//       </a>
// <StyledInfoDiv>
//   <h1
//     style={{
//       textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
//       fontSize: "1.8rem",
//     }}
//   >
//     {product.name}
//   </h1>
//   <h2
//     style={{
//       textAlign: "center",
//       textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
//       opacity: ".9",
//       maxWidth: "400px",
//       fontSize: "1.4rem",
//     }}
//   >
//     {product.description}
//   </h2>
//   <h3 style={{ textAlign: "center", opacity: ".6", margin: "0 0 1rem" }}>
//     {getTranslation("productPage.clickToSee")}
//   </h3>

//   <ul>
//     {product.included.map((item, index) => (
//       <StyledCheckDiv key={index}>
//         <StyledCheck src={Check} alt="Check" />
//         {item}
//       </StyledCheckDiv>
//     ))}
//   </ul>
//         <button
// style={{
//   width: "200px",
//   marginTop: "1.5rem",
//   boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
// }}
//           onClick={() => addToCart(product)}
//           disabled={cart.some((item) => item.id === product.id)}
//         >
//           <StyledCartIcon src={ButtonCartIcon} alt="Add to cart" />
//           {cart.length === 0 ? product.price : product.price}
//         </button>
//       </StyledInfoDiv>
//     </StyledDiv>
//   );
// };

// export default ProductPage;

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { useContext } from "react";
import { CartContext } from "../context/CartContext";
import { useLanguage } from "../context/LanguageContext";
import ScrollToTop from "../components/ScrollToTop";
import ButtonCartIcon from "../assets/images/button-cart-icon.png";
import Check from "../assets/images/check.png";
import ProductCard from "../components/ProductCard";

const StyledImg = styled.img`
  width: 40vw;
  max-width: 600px;
  margin-top: 0.5rem;

  @media (max-width: 1199px) {
    width: 70vw;
    margin: 0;
  }

  @media (max-width: 649px) {
    width: 90vw;
    margin-top: -1rem;
  }
`;

const StyledCartIcon = styled.img`
  width: 20px;
  margin-right: 5px;
`;

const StyledInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCheck = styled.img`
  width: 20px;
`;

const StyledCheckDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;

  @media (max-width: 649px) {
    margin-left: -2rem;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  display: ${(props) => (props.visible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ProductModal = styled.div`
  background-color: white;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
  height: fit-content;
  flex-direction: column;

  @media (max-width: 849px) {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    position: fixed;
    top: 1rem;

    width: 80vw;
    height: 80vh;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  margin: 3rem auto;
  width: 90vw;
  justify-content: space-around;
  gap: 2rem;

  @media (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }
`;

const StyledProductsDiv = styled.div`
  display: flex;
  @media (max-width: 849px) {
    align-items: center;
    overflow-y: scroll;
    flex-direction: column;
  }
`;

const StyledP = styled.p`
  max-width: 400px;
  font-size: 1.1rem;
  text-align: center;
  margin: 0.5rem 0;
`;

const ProductPage = () => {
  const location = useLocation();
  const { product: initialProduct } = location.state;
  const { cart, addToCart } = useContext(CartContext);
  const { getTranslation } = useLanguage();

  const products = getTranslation("products");
  const product =
    products.find((p) => p.id === initialProduct.id) || initialProduct;
  const remainingProducts = products.filter((p) => p.id !== product.id);

  const [overlayVisible, setOverlayVisible] = useState(false);

  ScrollToTop();

  const openProductLink = () => {
    window.open(product.address, "_blank");
  };

  const handleAddToCartAndShowOverlay = () => {
    addToCart(product);
    setOverlayVisible(true);
  };

  const handleCloseOverlay = () => {
    setOverlayVisible(false);
  };

  return (
    <StyledDiv>
      <a href={product.address} target="_blank" rel="noreferrer">
        <StyledImg
          src={product.image}
          alt={product.name}
          onClick={openProductLink}
        />
      </a>
      <StyledInfoDiv>
        {/* <h1
          style={{
            textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
            fontSize: "1.8rem",
          }}
        >
          {product.name}
        </h1> */}
        <h2
          style={{
            textAlign: "center",
            textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
            opacity: ".9",
            maxWidth: "440px",
            fontSize: "1.4rem",
          }}
        >
          {product.description}
        </h2>

        <StyledP>{product.longDescription1}</StyledP>
        <StyledP>{product.longDescription2}</StyledP>
        <StyledP>{product.longDescription3}</StyledP>
        <a
          href={product.address}
          target="_blank"
          rel="noreferrer"
          style={{
            textAlign: "center",
            opacity: ".6",
            margin: ".7rem 0 1rem",
            fontSize: "1rem",
            color: "black",
          }}
        >
          {getTranslation("productPage.clickToSee")}
        </a>
        <ul>
          {product.included.map((item, index) => (
            <StyledCheckDiv key={index}>
              <StyledCheck src={Check} alt="Check" />
              {item}
            </StyledCheckDiv>
          ))}
        </ul>
        <button
          onClick={handleAddToCartAndShowOverlay}
          disabled={cart.some((item) => item.id === product.id)}
          style={{
            width: "200px",
            marginTop: "1.5rem",
            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.15)",
          }}
        >
          <StyledCartIcon src={ButtonCartIcon} alt="Add to cart" />
          {product.price}
        </button>
      </StyledInfoDiv>

      {/* Overlay for showing other products */}
      <Overlay visible={overlayVisible} onClick={handleCloseOverlay}>
        <ProductModal
          // onClick={(e) => e.stopPropagation()}
          onClick={handleCloseOverlay}
        >
          <h2>{getTranslation("cart.othersBought")}</h2>
          <StyledProductsDiv onClick={handleCloseOverlay}>
            {remainingProducts.map((remainingProduct, index) => (
              <ProductCard key={index} product={remainingProduct} />
            ))}
          </StyledProductsDiv>
        </ProductModal>
      </Overlay>
    </StyledDiv>
  );
};

export default ProductPage;
