// import React from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { CartProvider } from "./context/CartContext";
// import Header from "./components/Header";
// import Cart from "./components/Cart";
// import PaymentPage from "./pages/PaymentPage";
// import HomePage from "./pages/HomePage";
// import ProductPage from "./pages/ProductPage";
// import "./App.css";

// const App = () => {
//   return (
//     <CartProvider>
//       <Router>
//         <Header />
//         <Routes>
//           <Route path="/" element={<HomePage />} />
//           <Route path="/cart" element={<Cart />} />
//           <Route path="/payment" element={<PaymentPage />} />
//           <Route path="/product-page" element={<ProductPage />} />
//         </Routes>
//       </Router>
//     </CartProvider>
//   );
// };

// export default App;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CartProvider } from "./context/CartContext";
import { LanguageProvider } from "./context/LanguageContext"; // Import LanguageProvider
import Header from "./components/Header";
import Footer from "./components/Footer";
import Cart from "./components/Cart";
import PaymentPage from "./pages/PaymentPage";
import HomePage from "./pages/HomePage";
import ProductPage from "./pages/ProductPage";
import Confirmation from "./pages/Confirmation";
import "./App.css";

const App = () => {
  return (
    <LanguageProvider>
      <CartProvider>
        <Router>
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/payment" element={<PaymentPage />} />
            <Route path="/product-page" element={<ProductPage />} />
            <Route path="/confirmation" element={<Confirmation />} />
          </Routes>
          <Footer />
        </Router>
      </CartProvider>
    </LanguageProvider>
  );
};

export default App;
