import React, { useState } from "react";
import styled from "styled-components";
import { useLanguage } from "../context/LanguageContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

// Styled components
const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 15px;

  // @media (max-width: 549px) {
  //   flex-direction: column;
  //   gap: 10px;
  // }
`;

const MailLink = styled.a`
  color: black;
  cursor: pointer;
  font-size: 0.9rem;

  @media (max-width: 549px) {
    font-size: 0.7rem;
  }
`;

const Copyright = styled.div`
  text-align: center;

  @media (max-width: 549px) {
    font-size: 0.7rem;
  }
`;

const TermsLink = styled.span`
  color: black;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.9rem;

  @media (max-width: 549px) {
    font-size: 0.7rem;
  }
`;

const Footer = () => {
  const { getTranslation } = useLanguage();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const currentYear = new Date().getFullYear();

  return (
    <FooterContainer>
      {/* Left: Mail link */}
      <MailLink href="mailto:post@eventen.no">post@eventen.no</MailLink>

      {/* Center: Copyright */}
      <Copyright>Eventen © {currentYear}</Copyright>

      {/* Right: Terms and Conditions link */}
      <TermsLink onClick={handleOpen}>
        {getTranslation("cart.termsAndConditions")}
      </TermsLink>

      {/* Terms and Conditions Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: "100000" }}
      >
        <DialogTitle id="alert-dialog-title">
          {getTranslation("cart.termsAndConditions")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>{getTranslation("cart.terms1")}</p>
            <p>{getTranslation("cart.terms2")}</p>
            <p>{getTranslation("cart.terms3")}</p>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </FooterContainer>
  );
};

export default Footer;
