import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useLanguage } from "../context/LanguageContext";
import ScrollToTop from "../components/ScrollToTop";
import { CartContext } from "../context/CartContext";

const ConfirmationPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getTranslation } = useLanguage();
  const { clearCart } = useContext(CartContext);

  ScrollToTop(); // Scroll to top of the page

  useEffect(() => {
    localStorage.removeItem("eventenCart");
    setTimeout(() => {
      clearCart();
    }, 1000);
  }, []);

  // Extract order details from the location state
  const { name, orderNumber, totalPrice, cartItems, discount } =
    location.state || {};

  const handleBackToHome = () => {
    navigate("/"); // Navigate back to home page
  };

  return (
    <ConfirmationContainer>
      <ConfirmationBox>
        <h1>{getTranslation("cart.congratulations")}</h1>
        <h3 style={{ fontWeight: 100 }}>
          {getTranslation("cart.hi")}
          <strong>{name}</strong>, <br />
        </h3>
        <p>{getTranslation("cart.haveSent")}</p>

        <OrderDetails>
          <p
            style={{
              textAlign: "center",
              marginBottom: "2rem",
              fontSize: "1.1rem",
            }}
          >
            {getTranslation("cart.order")}
            <strong>{orderNumber}</strong>
          </p>
          <ul>
            {cartItems.map((item, index) => (
              <li key={index} style={{ marginBottom: 10 }}>
                <span
                  style={{
                    fontSize: "1rem",
                  }}
                >
                  - {item.name}
                </span>{" "}
                ({item.price})
              </li>
            ))}
          </ul>
          <p className="discount">
            {getTranslation("cart.discount")}
            <strong style={{ color: "green" }}> - {discount},-</strong>
          </p>
          <p className="total">
            {getTranslation("cart.totalPrice")}
            <strong>{totalPrice},-</strong>
          </p>
        </OrderDetails>

        <Button onClick={handleBackToHome}>
          {" "}
          {getTranslation("cart.toHome")}
        </Button>
      </ConfirmationBox>
    </ConfirmationContainer>
  );
};

// Styled Components for styling
const ConfirmationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
`;

const ConfirmationBox = styled.div`
  margin-top: -4rem;
  background-color: #ffffff;
  padding: 20px 35px;
  width: 70vw;
  max-width: 400px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-size: 24px;
    color: #333;
  }

  p {
    font-size: 16px;
    color: #666;
    margin-top: 10px;
  }

  strong {
    color: #333;
  }
`;

const OrderDetails = styled.div`
  background-color: #f9f9f9;
  padding: 5px 20px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: left;
  width: 100%;

  h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 25px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      font-size: 16px;
      color: #555;
      margin-bottom: 8px;
    }
  }

  .total {
    font-size: 16px;
    font-weight: bold;
    margin: 12px 0;

    color: #333;
    text-align: right;
  }

  .discount {
    font-size: 15px;
    margin: 20px 0 12px 0;

    color: #333;
    text-align: right;
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  margin-top: 20px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

export default ConfirmationPage;
