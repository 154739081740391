// // components/ProductCard.jsx
// import React, { useContext } from "react";
// import { useNavigate } from "react-router-dom";
// import styled from "styled-components";
// import ButtonCartIcon from "../assets/images/button-cart-icon.png";
// import { CartContext } from "../context/CartContext";

// const StyledProductCard = styled.div`
//   padding: 20px 30px 30px;
//   margin: 10px;
//   text-align: center;
//   border-radius: 20px;
//   max-width: 300px;
//   cursor: pointer;
//   transition: box-shadow 0.2s ease;
//   display: flex;
//   flex-direction: column;
//   align-items: center;

//   &:hover {
//     box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
//   }
// `;

// const StyledImg = styled.img`
//   width: 100%;
// `;

// const StyledCartIcon = styled.img`
//   width: 20px;
//   margin-right: 5px;
// `;

// const ProductCard = ({ product }) => {
//   const { addToCart } = useContext(CartContext);
//   const navigate = useNavigate();

//   const handleClick = () => {
//     navigate(`/product-page`, { state: { product } });
//   };

//   const handleAddToCart = (e) => {
//     e.stopPropagation(); // Prevents the click event from bubbling up
//     addToCart(product);
//   };

//   return (
//     <StyledProductCard onClick={handleClick}>
//       <StyledImg src={product.image} alt={product.name} />
//       <h2 style={{ textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)" }}>
//         {product.name}
//       </h2>
//       <button
//         style={{ boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)" }}
//         onClick={handleAddToCart}
//       >
//         <StyledCartIcon src={ButtonCartIcon} alt="Add to cart" />
//         {product.price}
//       </button>
//     </StyledProductCard>
//   );
// };

// export default ProductCard;

// components/ProductCard.jsx
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ButtonCartIcon from "../assets/images/button-cart-icon.png";
import { CartContext } from "../context/CartContext";
import { useLanguage } from "../context/LanguageContext";
import PopupMessage from "../components/PopupMessage/PopupMessage";

const StyledProductCard = styled.div`
  padding: 20px 30px 30px;
  margin: 10px;
  text-align: center;
  border-radius: 20px;
  max-width: 250px;
  cursor: pointer;
  transition: box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);
  }
`;

const StyledImg = styled.img`
  width: 100%;
`;

const StyledCartIcon = styled.img`
  width: 20px;
  margin-right: 5px;
`;

const ProductCard = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const { getTranslation } = useLanguage();
  const [showPopupMessage, setShowPopupMessage] = useState(false); // For success message

  const handleClick = () => {
    navigate(`/product-page`, { state: { product } });
  };

  const handleAddToCart = (e) => {
    e.stopPropagation(); // Prevents the click event from bubbling up
    addToCart(product);
    setShowPopupMessage(true);
    setTimeout(() => {
      setShowPopupMessage(false);
    }, 3500);
  };

  return (
    <StyledProductCard onClick={handleClick}>
      {showPopupMessage && (
        <PopupMessage message={getTranslation("cart.toCart")} />
      )}

      <StyledImg src={product.image} alt={product.name} />
      <h2
        style={{
          textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
          fontSize: "1.4rem",
        }}
      >
        {product.name}
      </h2>
      <button
        style={{ boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)" }}
        onClick={handleAddToCart}
      >
        <StyledCartIcon
          src={ButtonCartIcon}
          alt={getTranslation("productCard.addToCart")}
        />
        {product.price}
      </button>
    </StyledProductCard>
  );
};

export default ProductCard;
